'use client'
import { SearchIcon } from 'lucide-react'
import { useRouter } from 'next/navigation'
import React, { useState } from 'react'

const SearchBar = () => {
    const [isOpen, setIsOpen] = useState(false)
    const router = useRouter()
    const [query, setQuery] = useState('')

    const handleSearch = () => {
        if (isOpen) {
            if (query !== '') {
                // Remove unwanted characters from the query
                const cleanedQuery = query.replace(/[+*()[\]]/g, '');
                router.push(`/Search/${encodeURIComponent(cleanedQuery)}`)
            }
        }
        setIsOpen(!isOpen)
    }
    

    // Function to handle keypress (Enter key)
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && query !== '') {
            handleSearch()
        }
    }

    return (    
        <div className='fixed bottom-6 left-5 flex flex-row-reverse items-center w-1/6 z-40'>
            <input
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                placeholder='البحث ...'
                onKeyDown={handleKeyDown}  // Add the onKeyDown event to detect Enter key
                className={`py-2 ml-3 bg-slate-800 dark:bg-white ${isOpen ? 'w-full px-3' : 'w-0'} 
                rounded-full transition-all duration-150 text-white dark:text-black origin-right`}
                style={{ direction: 'rtl' }}
            />
            <button onClick={handleSearch} className='bg-primary p-3 rounded-full cursor-pointer text-white absolute left-0'>
                <SearchIcon />
            </button>
        </div>
    )
}

export default SearchBar
