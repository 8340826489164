import(/* webpackMode: "eager" */ "/time4news/frontend/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/time4news/frontend/components/NavList2.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/time4news/frontend/components/SearchBar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/time4news/frontend/components/ThemeProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/time4news/frontend/components/ThemeToggler.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider","Tooltip","TooltipTrigger","TooltipContent"] */ "/time4news/frontend/components/ui/tooltip.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/time4news/frontend/node_modules/holy-loader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/time4news/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/time4news/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/time4news/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/time4news/frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/time4news/frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Rubik\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"]}],\"variableName\":\"rubik\"}");
