import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

const NavLogo = ({style}) => {
  return (
        <Link className={`block ${style}`} href={`/`} >
            <img className='block w-full dark:hidden' src={`/logoBlack.png`} width={300} height={300}/>
            <img className='hidden w-full dark:block' src={`/logoWhite.png`} width={300} height={300}/>
        </Link>
  )
}

export default NavLogo