'use client'
import { ChevronDown, HomeIcon, XIcon } from 'lucide-react'
import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'
import { RiMenu2Line } from "react-icons/ri";
import { Button } from './ui/button';
import NavLogo from './NavLogo';
const menuList = [

    {
        Title:
        <><HomeIcon className='hidden md:inline' /><span className='md:hidden'>الصفحة الرئيسية</span></>,
        Link:'/'
    }
    ,
    {
        Title: "كرة القدم",
        Link: "/Category/كرة_القدم"
    },
    {
        Title: "كرة السلة",
        Link: "/Category/كرة_السلة"
    },
    {
        Title: "التنس",
        Link: "/Category/التنس"
    },
    {
        Title: "رياضات مائية",
        Link: "/Category/رياضات_مائية"
    },
    {
        Title: "موتو سبور",
        Link: "/Category/موتو_سبور"
    },
    {
        Title: "فنون قتالية",
        Link: "/Category/فنون_قتالية"
    },
    {
        Title: "رياضات أمريكية",
        Link: "/Category/رياضات_أمريكية"
    },
    {
        Title: "رياضات متنوعة",
        Link: "/Category/رياضات_متنوعة"
    },
    {
        Title: "لاعبون وأحداث",
        Link: "/Category/لاعبون_وأحداث"
    }
];

const NavList2 = () => {
    const [navOpen,setNavOpen] = useState('-left-[100%]')
    const menuRef = useRef(null);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setNavOpen('-left-[100%]');
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    return (
        <nav className="bg-primary  shadow-md  md:px-8 rounded-md z-50">
        <div>
            <ul className="hidden md:flex flex-row justify-around ">
                {
                    menuList.map((ml,index)=>(
                        <li key={`nav_list_menu_${index}`} className="font-bold  text-white hover:bg-secondary hover:text-primary p-3 px-3 transition-all duration-300 border-primary hover:border-accent border-t-2"><Link  href={ml.Link}><h3 className="mr-1 font-bold">{ml.Title}</h3> </Link></li>
                    ))
                }
            
            </ul>
        </div>
        <div className='flex h-full justify-end md:hidden'>
            <Button onClick={()=>setNavOpen('left-0')} variant="icon">
                <RiMenu2Line className='text-xl'/>
            </Button>
        </div>
        
        <div ref={menuRef} className={`fixed py-4 top-0 ${navOpen} transition-all duration-300 w-2/3 bg-secondary z-50 h-full md:hidden  overflow-y-scroll px-6`}>
            <div className='flex flex-row justify-between items-center'>
                <Button onClick={()=>setNavOpen('-left-[100%]')} size="icon" variant="icon">
                    <XIcon  />
                </Button>
                <NavLogo style={`w-3/4 mx-auto`} />

            </div>
            <ul className='mt-5 '>
            {
                    menuList.map((ml,index)=>(
                        <li key={`mn_b_${index}`} className="text-sm font-bold px-2 py-1 rounded-sm dark:hover:bg-secondary hover:bg-primary text-black dark:text-white hover:text-white leading-8"><Link href={ml.Link}>{ml.Title}</Link></li>

                    ))
                }

            </ul>
        </div>
    </nav>
    )
}

export default NavList2